@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.payment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  width: 100%;
  height: 100vh;

  @include media-breakpoint-down(md) {
    height: calc(100vh - 60px);
  }

  &__content {
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    background-color: var(--white);
    box-shadow: rgba(95, 95, 95, 0.1) 0px 2px 4px 2px;

    @include media-breakpoint-down(md) {
      padding: 15px;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;
  }

  &__qr {
    width: 350px;
    height: 350px;
    object-fit: contain;
    background-color: #f1f2f6;
  }

  &__desc {
    padding: 8px 0;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.4;
  }

  &__times {
    display: inline-block;
    min-width: 50px;
    color: var(--primary-color);
    font-weight: 500;
  }
}
