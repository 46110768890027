@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.checkout {
  padding-bottom: 30px;
  background-color: #f7f7f7;

  &__top {
    background-color: var(--white);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 15px;
    height: 200px;

    @include media-breakpoint-down(lg) {
      padding: 20px 0;
      align-items: flex-start;
    }

    @include media-breakpoint-down(md) {
      padding: 8px 0;
      height: auto;
    }
  }

  &__heading {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.5;

    @include media-breakpoint-down(lg) {
      font-size: 2.5rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }

  &__name {
    margin-top: 8px;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }

  &__carts {
    padding: 0 10px;
    max-height: 60vh;
    overflow: auto;
  }

  &__group {
    &-lap {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &__group,
  &__right {
    padding: 20px;
    margin: 24px auto 0;
    border-radius: 6px;
    background-color: var(--white);
    box-shadow: rgba(95, 95, 95, 0.1) 0px 2px 4px 1px;

    &-title {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.4;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 200px;

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.4;
    }

    &-cost {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &__total {
    margin-top: 20px;

    &-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-title,
    &-value {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.4;
      color: inherit;
    }
  }

  &__payment {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    &-group {
      flex: 1;
    }

    &-warning {
      margin-top: 20px;
      padding: 15px;
      max-width: 85%;
      color: var(--white);
      border-radius: 8px;
      background-color: rgb(255, 196, 0);

      @include media-breakpoint-down(lg) {
        margin-bottom: 15px;
        max-width: 100%;
      }
    }

    &-qr {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(lg) {
        align-items: flex-start;
        margin-top: 16px;
        width: 100%;
      }
      @include media-breakpoint-down(md) {
        align-items: center;
      }
    }

    &-desc {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.5;
      color: #9a9a9a;
    }

    &-thumb {
      width: 80%;

      @include media-breakpoint-down(lg) {
        width: 40%;
        margin: 0 auto;
      }

      @include media-breakpoint-down(md) {
        margin-top: 8px;
        width: 90%;
      }
    }

    &-information {
      font-size: 1.4rem;
      text-align: center;
      color: #224f89;

      @include media-breakpoint-down(lg) {
        margin: 0 auto;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      gap: 16px;
    }

    &-img {
      width: 140px;
      height: 140px;
    }

    &-thumb {
      width: 100%;
      height: 0px;
      padding-top: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      background-image: url('../../assets/images/checkout/ilus-app-features.svg');
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 24px;

      @include media-breakpoint-down(lg) {
        padding: 0;
      }
    }

    &-pays {
      display: flex;
      align-items: center;
      gap: 50px;
      margin-top: 16px;

      @include media-breakpoint-down(lg) {
        justify-content: center;
      }
    }

    &-pay {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
}

.delivery-time {
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #9a9a9a;
  }

  &__value {
    margin-top: 4px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.4;
  }
}

.address {
  &__title {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #9a9a9a;

    &-detail {
      display: block;
      margin: 16px 0;
    }
  }

  &__group {
    display: flex;
    align-items: center;
    gap: 20px;
    user-select: none;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__floor,
  &__building,
  &__classroom {
    position: relative;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 200px;
    min-width: 100px;
    height: 45px;
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid #d2d1d6;
    cursor: pointer;
    transition: opacity ease-in 0.3s;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    &--dropdown {
      border-color: var(--primary-bg);
    }

    &-title {
      color: var(--primary-bg);
      transition: color ease-in 0.2s;
    }

    &-list {
      position: absolute;
      top: 50px;
      left: 0;
      padding: 10px 0;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      transform-origin: top right;
      width: 100%;
      max-height: 180px;
      border-radius: 6px;
      background-color: var(--white);
      outline: 1px solid var(--white);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      overflow: auto;
      z-index: 2;
      transition: visibility, opacity, transform;
      transition-duration: 0.3s;
      transition-timing-function: linear;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      font-size: 1.5rem;
      font-weight: 500;

      &:hover {
        background-color: #d8f0df;
      }
    }

    &-checked {
      width: 10px;
      height: 5px;
      border: 2px solid;
      border-color: transparent transparent var(--primary-bg) var(--primary-bg);
      rotate: -45deg;
    }

    &--show {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  &__arrow {
    rotate: 180deg;
    transition: rotate, filter;
    transition-duration: 0.3s;
    transition-timing-function: linear;

    &--show {
      rotate: 0deg;
      filter: brightness(0) saturate(100%) invert(51%) sepia(84%) saturate(3332%) hue-rotate(118deg) brightness(94%)
        contrast(101%);
    }
  }

  &__detail-input {
    width: 60%;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}

.no-products {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0;
  text-align: center;

  @include media-breakpoint-down(md) {
    padding: 50px 15px;
  }

  &__thumb {
    width: 350px;
    height: 350px;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      width: 250px;
      height: 250px;
    }
  }

  &__info {
    margin-top: 16px;
    @include media-breakpoint-down(md) {
      padding: 0 20px;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5;
  }

  &__desc {
    margin-top: 8px;
    font-weight: 500;
    line-height: 1.5;
    color: #676767;
  }

  &__link {
    display: block;
    padding: 16px 0;
    font-size: 1.6rem;
    color: #00a5cf;

    &:hover {
      opacity: 0.9;
    }
  }
}

// Radio MUI
:global {
  .checkout__pay-title {
    margin-bottom: 8px;
    font-size: 1.7rem !important;
    color: #9a9a9a !important;
    font-family: var(--font-family) !important;

    &.Mui-focused {
      color: #00b14f !important;
    }
  }

  .radio-button {
    color: #00b14f !important;

    &.Mui-checked {
      color: #00b14f !important;
    }

    .MuiSvgIcon-root {
      font-size: 2rem !important;
    }
  }

  .radio-label {
    .MuiTypography-root {
      font-size: 1.6rem !important;
      font-family: var(--font-family) !important;
    }
  }
}

.cart {
  &__products {
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-heading {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 144.444%;
    }

    &-delete-all {
      padding: 4px;
      font-size: 1.6rem;
      font-weight: 500;
      color: #f44336;
      cursor: pointer;
      background-color: transparent;
    }
  }

  &__products + &__products {
    margin-top: 20px;
  }

  &__summary {
    padding-top: 24px;

    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #f0efef;
    }

    &-price {
      color: var(--primary-color);
    }

    &-price,
    &-desc {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 144.444%;
    }
  }
}
