@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.item-wrapper {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffff;
  border-radius: 4px;
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.08);

  @include media-breakpoint-down(md) {
    padding: 10px;
  }
}

.item {
  &__shop-info {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 20px 0;
    //   border-bottom: 1px solid #ccc;
    border-bottom: 1px dashed #ccc;

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }

  &__shop-name {
    display: flex;
    gap: 10px;
    color: var(--primary-color);
  }

  &__status {
    color: var(--status-color, var(--primary-color));
    cursor: pointer;

    &--red {
      color: #f44336;
    }
  }
}

.sub-item {
  &__info-container {
    display: flex;
    padding: 20px 0;
    // justify-content: space-between;
    // align-items: center;
    // border-bottom: 1px h #ccc;
    border-bottom: 1px dashed #ccc;

    @include media-breakpoint-down(md) {
      padding: 15px 0;
      // flex-direction: column;
    }
  }

  &__img {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #e1e1e3;
    background-color: #f1f2f6;
    // padding: 1px;

    @include media-breakpoint-down(lg) {
      width: 100px;
      height: 100px;
    }

    @include media-breakpoint-down(md) {
      width: 80px;
      height: 80px;
    }
  }

  &__info {
    margin-left: 20px;
    // gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: 500;
    flex-grow: 1;

    @include media-breakpoint-down(md) {
      margin-left: 10px;
    }
  }

  &__name {
    width: 80%;
    font-size: 2rem;
    font-weight: 600;
    display: block;
    display: -webkit-box;
    line-height: 1.5;
    -webkit-line-clamp: 1; /* Số dòng bạn muốn giới hạn */
    -webkit-box-orient: vertical;
    visibility: visible; /* Tránh bug không giới hạn số dòng được do trình duyệt */
    text-overflow: ellipsis;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      width: 95%;
    }
  }

  &__desc {
    width: 80%;
    display: block;
    display: -webkit-box;
    line-height: 1.5;
    -webkit-line-clamp: 2; /* Số dòng bạn muốn giới hạn */
    -webkit-box-orient: vertical;
    visibility: visible; /* Tránh bug không giới hạn số dòng được do trình duyệt */
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__quantity {
    color: var(--secondary-text-color);
  }

  &__price {
    margin: auto 0;
    font-size: 1.8rem;
    font-weight: 500;
    flex-shrink: 0;
    color: var(--primary-color);

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
    }
  }
}

// .address {
//   &__container {
//     margin-top: 15px;
//     display: flex;
//     gap: 10px;
//     font-size: 1.7rem;
//     font-weight: 500;
//   }

//   &__value {
//     display: block;
//     display: -webkit-box;
//     line-height: 1.5;
//     -webkit-line-clamp: 2; /* Số dòng bạn muốn giới hạn */
//     -webkit-box-orient: vertical;
//     visibility: visible; /* Tránh bug không giới hạn số dòng được do trình duyệt */
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
// }

.note,
.payment-method,
.address,
.date-create {
  &__container {
    margin-top: 15px;
    display: flex;
    gap: 10px;
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__value {
    display: block;
    display: -webkit-box;
    line-height: 1.5;
    -webkit-line-clamp: 2; /* Số dòng bạn muốn giới hạn */
    -webkit-box-orient: vertical;
    visibility: visible; /* Tránh bug không giới hạn số dòng được do trình duyệt */
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.total {
  &__container {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-down(md) {
      gap: 10px;
    }

    &-btn {
      width: 30%;
      margin-left: auto;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    font-size: 2rem;
    font-weight: 500;
    justify-content: space-between;
    color: var(--primary-color);

    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
    }
  }
}
