@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.footer {
  padding: 44px 0 12px;
  color: #fff;
  background-color: var(--primary-bg);

  @include media-breakpoint-down(lg) {
    padding-top: 35px;
  }

  &__logo {
    width: 150px;
    height: 70px;
    object-fit: cover;

    @include media-breakpoint-down(lg) {
      width: 120px;
      height: 60px;
    }
  }

  &__item {
    display: block;
    padding: 12px 0;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 144.444%;
    color: inherit;
    transition: opacity;
    transition-duration: 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 15px;
  }

  &__social-link {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: translate;
    transition-duration: 0.3s;

    @include media-breakpoint-down(sm) {
      width: 42px;
      height: 42px;
    }

    &:hover {
      translate: 0 -3px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    padding: 10px 0;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &-group,
    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-group {
      gap: 32px;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        gap: 20px;
      }
    }

    &-text,
    &-link {
      font-size: 1.5rem;
      color: inherit;
    }
  }
}
