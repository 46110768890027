@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.product {
  display: flex;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid #f0efef;

  &__mobile-quantity {
    margin-right: 10px;

    @include media-breakpoint-down(lg) {
      display: block;
    }

    &-btn {
      padding: 10px;
      color: #00a5cf;
      border-radius: 4px;
      border: 1px solid #c5c5c5;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &__img-wrap {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }

  &__thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    background-color: #f1f2f6;
  }

  &__detail {
    margin-left: 12px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 70%;
      font-weight: 500;
      word-break: break-word;
      @include media-breakpoint-down(md) {
        max-width: 65%;
        font-size: 1.4rem;
      }
    }

    &-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
    }

    &-price {
      font-size: 1.5rem;
      line-height: 1.4;
      word-wrap: break-word;
    }

    &-delete {
      padding: 4px 8px;
      font-size: 1.6rem;
      font-weight: 500;
      color: #f44336;
      cursor: pointer;
      background-color: transparent;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.change-quantity {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  opacity: 0;
  transform: translateY(100%);
  transition: transform, opacity;
  transition-duration: 0.3s;
  z-index: 9999;
  overflow: auto;

  &--show {
    opacity: 1;
    transform: translateY(0);
  }

  &__close {
    position: absolute;
    top: 0;
    left: 10px;
    padding: 10px;
    background-color: transparent;
    filter: brightness(0) saturate(100%) invert(86%) sepia(0%) saturate(0%) hue-rotate(181deg) brightness(90%)
      contrast(85%);
    cursor: pointer;
  }

  &__icon {
    width: 2.8rem;
    height: 2.8rem;
  }

  &__img {
    width: 100%;
    object-fit: cover;
    height: 25vw;
    min-height: 50px;
    background-color: #f1f2f6;

    @include media-breakpoint-down(lg) {
      height: 50vw;
    }
    @include media-breakpoint-down(md) {
      height: 50vw;
    }
  }

  &__first,
  &__last {
    padding: 16px;
  }

  &__name {
    font-size: 2rem;
  }

  &__desc,
  &__price {
    padding-top: 8px;
    font-size: 1.5rem;
    line-height: 1.5;
    color: #9a9a9a;
  }

  &__price {
    color: var(--primary-color);
  }

  &__last {
    border-top: 8px solid #f7f7f7;
  }

  &__title {
    font-size: 1.6rem;
    word-break: break-word;
  }

  &__btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
  }

  &__quantity-btn {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #f0efef;
    background-color: transparent;
    cursor: pointer;
  }

  &__quantity-number {
    width: 64px;
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }

  &__footer {
    padding: 16px;
    background: #fff;
    box-shadow: 0 -2px 6px 0 rgba(28, 28, 28, 0.06);
  }
}
