@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.overlay-hidden {
  display: none;
  transition: all 0.5s;
}

.overlay {
  display: block;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 9999;
}

.drawer-container {
  width: 30%;
  height: 100%;
  background-color: #fff;
  // position: fixed;
  inset: 0;
  z-index: 99999;
  // top: 0;
}

.product {
  &__wrapper {
    padding: 20px;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    border: 1px solid transparent;
    background-color: #f7f7f7;
    box-shadow: 1px 4px 6px 2px rgba(28, 28, 29, 0.07);
    border-radius: 4px;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr 4fr;
    }

    &:hover {
      border: 1px solid var(--primary-color);
    }
  }

  &__img {
    width: 100%;
    height: auto;
    min-width: 80px;
    min-height: 80px;
    object-fit: cover;
    border-radius: 4px;

    background-color: #f1f2f6;
  }

  &__info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include media-breakpoint-down(md) {
      margin-left: 15px;
    }
  }

  &__name {
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__desc {
    color: var(--secondary-text-color);
    height: 80px;
    // overflow: hidden;
    // line-height: 1.5;
    // display: -webkit-box;
    // -webkit-line-clamp: 3; /* Số dòng tối đa */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    display: block;
    display: -webkit-box;
    line-height: 1.5; /* Fallback  */
    max-height: 74px; /* Fallback số dòng bạn muốn giới hạn */
    -webkit-line-clamp: 3; /* Số dòng bạn muốn giới hạn */
    visibility: visible; /* Tránh bug không giới hạn số dòng được do trình duyệt */
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
  }

  &__last-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__price {
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__add-cart-btn {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--primary-color);
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 3px;
      background-color: white;
    }

    &::before {
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
    }
  }
}
