@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.check-icon {
  margin-top: 5px;
  width: 14px;
  height: 14px;
  color: var(--primary-color);
}

.refresh-icon {
  width: 16px;
  height: 16px;
  transition: 0.3s linear;
}

.strong {
  font-weight: 600;
  color: var(--primary-color);
}

.update-btn {
  width: 150px;
  height: 50px;
  background-color: var(--primary-color);
  transition: 0.5s;

  color: white;
  border-radius: 5px;
  font-weight: 500;

  @include media-breakpoint-down(lg) {
    margin-top: 10px;
    width: 130px;
    font-size: 1.5rem;
    padding: 5px 10px;
  }
}

.isDisabled {
  opacity: 0.4;
  user-select: none;
  pointer-events: none;
}

.btn-check-icon {
  width: 24px;
  height: 24px;
}

.btn-power-icon {
  width: 20px;
  height: 20px;
}

.btn-power-off {
  background-color: #ef5350;
  color: white;
}

.auth-twin {
  &-wrapper {
    color: var(primary-text-color);
    padding-right: 30px;
    width: 100%;
    @include media-breakpoint-down(lg) {
      padding-right: 0;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;

    margin-bottom: 20px;
  }
}

.first-row {
  gap: 10px;

  &__desc {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-top: 10px;
    font-size: 1.7rem;
    font-weight: 400;
    width: 100%;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}

.security-method {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 30px;
  &__title {
    font-size: 20px;
    font-weight: 600;
  }

  &__option-label {
    font-size: 1.7rem;
    font-weight: 600;
    // margin-left: 10px;
    color: var(--primary-color);
    @include media-breakpoint-down(lg) {
      display: block;
      width: 100%;
      margin-left: 0;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }
    }

    & input {
      margin-right: 10px;
      &:checked {
        color: var(--primary-color);
      }
    }
  }
}

.secret-key {
  &__container {
    margin-top: 30px;
    @include media-breakpoint-down(lg) {
      margin-top: 15px;
    }
  }

  &__first-row {
    display: flex;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  &__first-row {
    display: flex;
    align-items: center;
  }

  &__desc {
    font-size: 1.7rem;
    font-weight: 400;
    margin-right: 50px;
    display: flex;

    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }
  }

  &__label {
    margin-top: 10px;
    font-size: 1.7rem;
    font-weight: 400;
  }

  &__content {
    width: 100%;
  }

  &__value-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: all;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__value {
    font-size: 1.8rem;
    color: var(--primary-color);
    font-weight: 600;
    min-width: 60%;
    cursor: pointer;
    @include media-breakpoint-down(md) {
      margin-top: 10px;
      width: 300px;
      text-align: center;
      word-wrap: break-word; /* CSS 2.1 */
      overflow-wrap: break-word;
      // text-wrap: wrap;
    }
  }

  &__refresh-btn {
    background-color: transparent;
    border: 1px solid var(--text-color);
    margin-left: 30px;
    border-radius: 5px;

    & {
      p {
        transition: 0.3s linear;
        color: var(--text-color);
      }
    }

    &:hover {
      border: 1px solid var(--primary-color);
      color: var(--primary-color);

      & {
        p {
          color: var(--primary-color);
        }
      }

      & .refresh-icon {
        color: var(--primary-color);
      }
    }

    @include media-breakpoint-down(lg) {
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    & p {
      font-weight: 600;
    }
  }

  &__qr-code {
    width: 200px;
    height: 200px;
    object-fit: contain;
    border: 1px solid var(--primary-color);
    // padding: 5px;
    border-radius: 5px;
    @include media-breakpoint-down(lg) {
      width: 270px;
      height: 270px;
    }
  }
}

.otp {
  &__container {
    display: flex;
    align-items: center;
    margin-top: 30px;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__input {
    height: 40px;
    padding: 10px;
    width: 140px;
    font-size: 1.7rem;
    border: 1px solid var(--text-color);
    margin-left: 30px;
    border-radius: 5px;
  }

  &__btns {
    display: flex;
    margin: 30px 0;
    justify-content: center;
    gap: 30px;

    @include media-breakpoint-down(lg) {
      margin: 20px 0;
    }
  }

  &__note {
    font-size: 1.7rem;
    font-weight: 400;
    margin-left: auto;
    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.note {
  &__title {
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__desc {
    font-size: 1.7rem;
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    gap: 5px;
    @include media-breakpoint-down(lg) {
      // width: 100;
    }
  }
}
