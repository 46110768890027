@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.list-categorise {
  &__item {
    width: 100%;
    height: 100%;
    // padding: 0 5px;
    margin-bottom: 50px;
    cursor: pointer;
    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
      // padding: 0;
    }
  }
  .loader {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 50px !important;
    height: 50px !important;
    @include media-breakpoint-down(md) {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.category-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 10px;
  @include media-breakpoint-down(md) {
    margin-bottom: 5px;
  }
}

.category-name {
  color: var(--text-color);
  font-size: 1.8rem;
  font-weight: 600;
}
