@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.forgot-password {
  padding: 20px;
  width: 450px;
  min-height: 100px;
  text-align: center;
  align-self: center;
  border-radius: 12px;
  background-color: #fff;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 5px 0 rgba(51, 62, 73, 0.1);
  z-index: 999;

  @include media-breakpoint-down(md) {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 2px 5px 0 var(--white);
  }

  &__heading {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 146.667%;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }

  &__desc {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 146.667%;
    color: #9e9da8;

    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
    }
  }

  &__link {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 146.667%;
    color: var(--primary-color);

    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 142.857%;
    }
  }

  &__btn-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @include media-breakpoint-down(md) {
      gap: 20px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 144.444%;
    user-select: none;
  }
}

.captcha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @include media-breakpoint-down(md) {
    gap: 10px;
  }

  &__input {
    max-width: 50%;
  }

  &__img {
    width: 120px;
    height: 100%;
    object-fit: cover;
  }

  &__icon {
    padding: 4px;
    margin-top: 4px;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}
