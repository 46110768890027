@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.form {
  &__row {
    display: flex;
    gap: 30px;

    @include media-breakpoint-down(md) {
      gap: 20px;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 0;
    }

    &--three {
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 0;
      }
    }

    > * {
      flex: 1;
    }
  }

  &__group {
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      margin-top: 25px;
    }

    &--inline {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__text-area,
  &__text-input {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    border: 1.5px solid #d2d1d6;

    &--sm {
      padding: 10px;
      border-radius: 8px;
    }

    &:focus-within {
      border-color: var(--primary-color);
    }
  }

  &__text-area {
    height: 200px;

    &-input {
      border: none;
      outline: none;
      resize: none;
    }
  }

  &__text-input {
    position: relative;
    height: 50px;

    &--sm {
      height: 45px;
    }
  }

  &__input,
  &__text-area-input {
    flex: 1;
    width: 100%;
    height: 100%;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 144.444%;

    &::placeholder {
      font-size: 1.6rem;
      color: #acacac;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.6rem;
      line-height: 150%;
    }

    &--center {
      text-align: center;
    }
  }

  &__input-icon {
    margin-left: 12px;
    filter: brightness(0) saturate(100%) invert(69%) sepia(9%) saturate(268%) hue-rotate(206deg) brightness(91%)
      contrast(88%);

    &--err {
      filter: brightness(0) saturate(100%) invert(33%) sepia(33%) saturate(3942%) hue-rotate(341deg) brightness(99%)
        contrast(93%);
    }
  }

  &__input-btn {
    background-color: transparent;
    cursor: pointer;
  }

  &__error {
    margin-top: 8px;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
    color: #f44336;
  }

  &__checkbox {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 19px;
      height: 19px;
      border-radius: 5px;
      border: 1.5px solid #9e9da8;
    }
  }

  &__checkbox:has(:checked) {
    &::before {
      border-color: var(--primary-bg);
      background-color: var(--primary-bg);
    }

    &::after {
      top: 4px;
      left: 3px;
      width: 9px;
      height: 3px;
      border-radius: 0;
      rotate: -45deg;
      border: 3px solid;
      border-color: transparent transparent var(--white) var(--white);

      @include media-breakpoint-down(lg) {
        top: 3px;
      }

      @include media-breakpoint-down(md) {
        top: 2px;
      }
    }
  }

  &__checkbox-input {
    display: none;
  }

  &__checkbox-label {
    margin-left: 29px;
    color: inherit;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 146.667%;

    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 142.857%;
    }
  }

  &__pull-right {
    margin-left: auto;
  }

  &__label {
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 145.455%;

    &--lg {
      margin-bottom: 10px;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 144.444%;
    }

    &--medium {
      margin-bottom: 10px;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 144.444%;
    }

    &--sm {
      margin-bottom: 10px;
      font-size: 1.4rem;
      line-height: 142.857%;
    }
  }
}
