.breadcrumb {
  display: flex;
  font-size: 1.8rem;
  row-gap: 10px;
  flex-wrap: wrap;
  &__item-container {
    display: flex;
    align-items: center;
    gap: 10px;

    & a {
      color: #00a5cf;
    }
  }

  &__item {
  }

  &__icon {
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}
