@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.wallet {
  &__title {
    font-size: 2rem;
    font-weight: 600;
  }

  &__copy-icon {
    width: 24px;
    height: 24px;
    color: var(--primary-color);
  }

  &__transfer-content {
    &-container {
      width: 100%;
      height: 50px;
      margin-top: 15px;
      //   background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid var(--primary-color);
      cursor: pointer;
    }

    &-value {
      font-size: 2rem;
      font-weight: 600;
      color: var(--primary-color);

      @include media-breakpoint-down(lg) {
        font-size: 1.8rem;
      }
    }
  }

  &__logo-bank-container {
    width: 100%;
    height: 50px;
    margin-top: 15px;
    background-color: var(--primary-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 13%;

      @include media-breakpoint-down(lg) {
        width: 25%;
      }
    }
  }

  &__qr-info {
    margin-top: 20px;
    background-color: var();
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      margin-top: 5px;
      gap: 5px;
      font-size: 1.7rem;
      font-weight: 500;
    }

    &-value--green {
      font-weight: 600;
      color: var(--primary-color);
    }
  }

  &__qr-container {
    width: 40%;
    height: 40%;
    margin: 0 auto;
    margin-top: 25px;

    @include media-breakpoint-down(lg) {
      width: 75%;
      height: 75%;
    }

    border: 1px solid var(--primary-color);
    border-radius: 8px;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__note {
    &-container {
      padding: 10px;
      border-radius: 8px;
      margin-top: 25px;
      background-color: rgb(255, 196, 0, 0.78);
      font-size: 1.7rem;
      font-weight: 500;
      color: var(--text-color);;

      a {
        color: var(--primary-color);
      }
    }

    &-desc {
      display: flex;
      gap: 5px;
    }
  }
}
