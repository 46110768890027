@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.development-team {
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;

    &-bg {
      position: relative;
      padding-top: 50px;

      @include media-breakpoint-down(lg) {
        padding-top: 100px;
      }
      @include media-breakpoint-down(md) {
        padding-top: 0;
      }
    }

    &-thumb {
      width: min(450px, 100%);
      height: 450px;
      object-fit: contain;

      @include media-breakpoint-down(lg) {
        height: 300px;
      }
    }

    &-icon {
      position: absolute;
      top: 20%;
      left: 0;
      width: 5rem;
      height: 5rem;
      animation: rotate 2s linear infinite;
      filter: brightness(0) saturate(100%) invert(39%) sepia(67%) saturate(1668%) hue-rotate(122deg) brightness(97%)
        contrast(101%);

      @include media-breakpoint-down(lg) {
        width: 4rem;
        height: 4rem;
      }

      @include media-breakpoint-down(md) {
        width: 3.2rem;
        height: 3.2rem;
      }

      &--bt {
        top: initial;
        bottom: 30%;
        left: initial;
        right: 0;
        animation: rotate-reverse 2s linear infinite;
      }
    }
  }

  &__main {
    padding-bottom: 100px;
    background: url("../../assets/images/about/bg2.svg") no-repeat;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 50px;

    & .title {
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.5;
    }

    & .desc {
      color: #79808a;
    }
  }
}

.developer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;

  @include media-breakpoint-down(lg) {
    padding: 20px 0;

    &:hover {
      .developer__socials {
        transform: translateY(0);
        visibility: visible;
      }
    }
  }

  &__top {
    position: relative;
    width: 100%;

    &:hover {
      .developer__socials {
        transform: translateY(0);
        visibility: visible;
      }
    }

    &--tab {
      .developer__socials {
        display: none;
      }
    }
  }

  &__avatar {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
    background-color: #d8d8d8;
    vertical-align: bottom;
    user-select: none;

    @include media-breakpoint-down(md) {
      height: 300px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    padding-top: 20px;
    width: 101%;
    background-color: var(--white);
    z-index: 10;
    user-select: none;
  }

  &__name {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }

  &__desc {
    color: #79808a;
  }

  &__socials {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    visibility: hidden;
    transform: translateY(100%);
    transition: transform, visibility;
    transition-duration: 0.3s;
    transition-timing-function: linear;

    &-link {
      padding: 8px;
    }

    &-icon {
      width: 2.4rem;
      height: 2.4rem;
    }

    &-info {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 80px;
      background-color: var(--primary-bg);
      clip-path: polygon(100% 40%, 0% 0%, 0% 100%, 100% 100%);
      z-index: 2;
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 10px;
    }

    &-bg {
      bottom: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 65px;
      background-color: #b1fcd2;
      z-index: 1;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-reverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
