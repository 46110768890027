@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.home {
  scroll-behavior: smooth;

  &__title-1,
  &__title-2,
  &__title-3,
  &__title-4 {
    font-size: 2.8rem;
    font-weight: 600;
    color: var(--text-color);

    &--highlight {
      color: var(--primary-color);
    }

    @include media-breakpoint-down(md) {
      font-size: 2rem;
      font-weight: 600;
    }
    &--highlight {
      color: var(--primary-color);
    }
  }

  &__title-1 {
    margin-top: 28px;
    margin-bottom: 48px;

    @include media-breakpoint-down(md) {
      margin-top: 48px;
      margin-bottom: 20px;
    }
  }

  &__title-2 {
    margin-bottom: 50px;
    margin-top: 60px;
    @include media-breakpoint-down(md) {
      margin-top: 48px;
      margin-bottom: 10px;
    }
  }

  &__title-3 {
    margin-top: 50px;
    // font-size: 3.6rem;
    // font-weight: 600;
    @include media-breakpoint-down(md) {
      margin-top: 15px;
    }
  }

  &__title-4 {
    // margin-top: 50px;
    // font-size: 3.6rem;
    margin: 30px 0;
  }

  &__sub-title {
    font-size: 2.4rem;
    color: var(--text-color);
    font-weight: 600;

    @include media-breakpoint-down(md) {
      font-size: 1.7rem;
    }
  }

  &__faq-text {
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 1.4rem;
  }

  &__search-result-loading {
    opacity: 0;
  }

  &__search-result-show {
    max-height: 1900px;
    opacity: 1;
    height: fit-content;

    @include media-breakpoint-down(lg) {
      max-height: 5500px;
    }
  }

  &__search-result-hidden {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }

  &__search-loading-container {
    // margin-top: 100px;
    min-height: 500px;
    max-height: 1300px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      margin-top: 150px;
      min-height: 200px;
      max-height: 1500px;
      align-items: flex-start;
    }
  }

  &__loading-icon {
    width: 80px !important;
    height: 80px !important;
    @include media-breakpoint-down(md) {
      width: 50px !important;
      height: 50px !important;
    }

    color: var(--secondary-text-color);
    animation: loading 0.5s infinite linear;
  }

  &__close-container {
    display: flex;
    align-items: center;
    height: 40px;
    border: 2px solid var(--secondary-text-color);
    border-radius: 5px;
    padding: 15px;
    overflow: hidden;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      height: 40px;
      width: fit-content;
    }
  }

  &__remove-tilte {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--secondary-text-color);
    padding-right: 10px;
    position: relative;
    transition: transform 0.5s;
    &::after {
      position: absolute;
      content: '';
      width: 1px;
      height: 100%;
      display: block;
      background-color: var(--secondary-text-color);
      right: 0;
      top: 0;
    }
    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
    }
  }

  &__close-icon {
    color: var(--secondary-text-color);
    width: 16px;
    margin-left: 5px;
    height: 16px;
    transition: transform 0.5s;
  }

  &__search-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(md) {
      margin-top: 48px;
      margin-bottom: 20px;
    }
  }

  &__search-title {
    font-size: 3.2rem;
    font-weight: 500;
    color: var(--text-color);
    margin-top: 35px;
    margin-bottom: 35px;
    @include media-breakpoint-down(md) {
      font-size: 2.2rem;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__btn {
    @include media-breakpoint-down(md) {
      font-size: 1.4rem !important;
      border-radius: 5px !important;
    }
  }
}

.sparate {
  margin-top: 80px;
  margin-bottom: 16px;
  border: 1px solid #e5e9f0;
  gap: 10px;
  @include media-breakpoint-down(md) {
    height: 8px;
    background-color: #f7f7f7;
    border: none;
    margin: 0;
  }
}

.home__close-container:hover > .home__remove-tilte {
  transform: translateX(-150%);
  color: rgb(244, 67, 54, 0.7);
}

.home__close-container:hover {
  border: 2px solid rgb(244, 67, 54, 0.7);
}
.home__close-container:hover > .home__close-icon {
  transform: scale(1.5) translateX(-100%);
  color: rgb(244, 67, 54, 0.7);
  @include media-breakpoint-down(md) {
    transform: scale(1.5) translateX(-15 0%);
  }
}

.reason-container {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  gap: 15px;
}

.reason-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  text-align: left;

  &__check-icon {
    width: 12px;
    height: 12px;
    margin-top: 5px;
    flex-shrink: 0;
    color: var(--primary-color);
  }

  &__keyword {
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--text-color);
  }

  &__text {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--text-color);
  }
}

// footer banner

.banner-footer {
  padding: 108px 0;
  background-color: #f7f7f7;
  margin-top: 30px;

  @include media-breakpoint-down(md) {
    padding: 48px 0;
  }
  &__left,
  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 14px;

    &-img {
      width: 150px;
      height: 150px;
      margin: 0 auto;
      padding-bottom: 25px;
    }
  }

  &__left {
    @include media-breakpoint-down(md) {
      margin-bottom: 48px;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 12px;
  }

  &__desc {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 24px;
  }

  &__logo-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 10px;
  }
}
