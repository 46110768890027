.chat-bot {
  cursor: pointer;
  padding: 16px 6px;
  min-width: 52px;
  height: 50%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  &__icon {
    width: 34px !important;
    height: 34px !important;
  }
  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 4px;
  }
}
