@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$logoWidth: 60px;
.logo {
  padding: 0 24px;
  width: 100%;
  height: $logoWidth;
  border-bottom: 1px solid #f5f5f5;

  &__img {
    padding-top: 10px;
    width: 100px;
    height: 100%;
    object-fit: cover;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - $logoWidth);
}
