@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.chat-modal {
  background-color: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px 20px 20px;
  display: flex;
  flex-direction: column;
  max-width: 45vw;
  max-height: 60vh;
  width: 45vw;
  height: 70vh;
  margin: 0 auto;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  @include media-breakpoint-down(xl) {
    width: 60vw;
    max-width: 60vw;
  }

  @include media-breakpoint-down(lg) {
    right: 0;
    bottom: -1px;
    padding: 15px 15px 33px;
    width: 100vw;
    max-width: 100vw;
    max-height: 65vh;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include media-breakpoint-down(md) {
    max-height: 70vh;
    padding-bottom: 20px;
  }
}

.chat-modal__title {
  margin-bottom: 25px !important;
  padding: 0 0 10px 0 !important;
  text-align: center !important;
  border-bottom: 1px solid #ccc !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: 0.008px !important;
  font-weight: 700 !important;
  color: rgb(39, 39, 42) !important;
}

.chat-modal__messages {
  height: 250px;
  flex: 1;
  overflow-y: auto;
  font-size: 15px;
  padding: 0 10px;
  clear: both;
  position: relative;
}

.chat-modal__message {
  margin-bottom: 15px !important;
  max-width: 70%;
  clear: both;
}

.chat-modal__message--chatbot {
  float: left;
  background-color: #f0f0f0;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.chat-modal__message--user {
  float: right;
  background-color: rgba(0, 186, 81, 0.2);
  padding: 10px;
  margin-left: 10px;
  border-radius: 4px;
}

.chat-modal__loading {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 0;
  clear: both;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #333;
  border-radius: 50%;
  animation: loading 1s infinite ease-in-out;
}

@keyframes loading {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.chat-modal__container {
  padding: 8px 48px 8px 16px;
  width: 100%;
  line-height: 21px;
  color: rgb(39, 39, 42);
  border: 1px solid rgb(166, 166, 176);
  resize: none;
  border-radius: 20px;
  outline: 0px;
  appearance: none;
  height: 40px;
  min-height: 40px;
  caret-color: #00ba51;
  display: flex;
  margin-top: 10px;
  position: relative;
  &:hover {
    border-color: #00ba51;
  }
}

.chat-modal__input {
  font-size: 1.5rem;
  border-color: rgb(39, 39, 42);
  position: absolute;
  width: 90%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    font-size: 1.6rem;
  }

  &::placeholder {
    font-size: 1.4rem;
  }
}

.chat-modal__button {
  cursor: pointer;
  user-select: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: 32px;
  height: 32px;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.chat-modal__divider {
  margin: 15px 0 0 0;
  border: none;
  border-top: 1px solid #ccc;
}
