.infinite-scroll {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.restaurant-list__item {
  padding-left: 0;
  padding-right: 0;
}

.list__loader {
  border-width: 10px;
  margin: 30px auto;
  width: 100px !important;
  height: 100px !important;
}

// .list__end {
//   width: 100%;
//   height: 1px;
//   background-color: var(--primary-color);
//   margin-bottom: 30px;
// }
