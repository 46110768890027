@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.list-container {
  margin-bottom: 50px;

  @include media-breakpoint-down(md) {
    margin-bottom: 25px;
  }
}

.list-title {
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.pagination {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  @include media-breakpoint-down(md) {
    gap: 10px;
  }
}

.page-item {
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #c7c7c7;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgb(103, 103, 103, 0.3);
  }
  @include media-breakpoint-down(md) {
    height: 35px;
    width: 35px;
  }
}

.page-item-link {
  display: flex;
  color: var(--text-color);
  width: 100%;
  height: 100%;
  font-weight: 700;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.page-item-active {
  position: relative;
  background-color: var(--primary-color);
  & a {
    color: white;
  }
}

.next-icon,
.prev-icon {
  width: 20px;
  height: 20px;
  color: var(--text-color);
}

.prev-icon {
  transform: rotate(180deg);
}
