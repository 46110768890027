.chats {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: calc(2vw - 10px);
  bottom: 15vh;
  width: 66px;
  height: 169px;
  border-radius: 12px;
  z-index: 999;
  background-color: var(--primary-bg);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 16px 0px;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 14px;
  opacity: 0.9;
}
