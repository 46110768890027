@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.chat {
  cursor: pointer;
  padding: 16px 6px;
  min-width: 52px;
  height: 50%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;

  &__icon {
    width: 32px !important;
    height: 32px !important;
  }

  &__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: rgb(255, 255, 255);
    margin-top: 4px;
  }

  &__modal {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    display: flex;
    width: 750px;
    height: 550px;
    outline: none;
    border-radius: 12px;
    background-color: var(--white);
    backdrop-filter: blur(10px);
    background-clip: padding-box;

    @include media-breakpoint-down(lg) {
      right: 15px;
      bottom: 10%;
      width: calc(100% - 30px);
    }

    @include media-breakpoint-down(md) {
      right: 0;
      bottom: 5%;
      width: 100%;
      height: 500px;
    }
  }
}
