@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  min-width: 100px;
  padding: 9px 16px;
  background-color: var(--white);
  border: 1px solid transparent;
  user-select: none;

  + .wrapper {
    margin-left: 8px;
  }

  &.authGoogle {
    margin-left: 0;
  }

  // Disabled
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.icon + .title,
.title + .icon {
  margin-left: 8px;
}

.icon {
  display: inline-block;
  width: 24px;
  text-align: center;
}

.primary {
  color: var(--white);
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  &:hover {
    border-color: var(--primary-color);
    opacity: 0.9;
  }
}

.outline {
  color: #676767;
  background-color: var(--white);
  border-color: #f0efef;

  &:hover {
    border-color: #f0efef;
  }

  .icon + .title,
  .title + .icon {
    margin-left: 0px;
  }
}

.haveProducts {
  color: var(--white);
  border-color: var(--primary-bg);
  background-color: var(--primary-bg);

  &:hover {
    border-color: var(--primary-bg);
  }

  .icon + .title,
  .title + .icon {
    margin-left: 8px;
  }

  .icon {
    filter: brightness(0) saturate(100%) invert(98%) sepia(2%) saturate(0%) hue-rotate(266deg) brightness(101%)
      contrast(102%);
  }
}

.checkout {
  width: 100%;
  padding: 14px 16px;
}

.action {
  font-size: 1.4rem;
  font-weight: 500;
  min-width: 42px;
  min-height: 42px;
  padding: 7px 10px;

  @include media-breakpoint-down(md) {
    padding: 8px 10px;
  }
}

.large {
  color: var(--secondary-text-color);
  width: 100%;
  height: 48px;
  font-weight: 600;
  border: 1px solid var(--secondary-text-color);
  transition: all 0.5s;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
}

.auth,
.authGoogle {
  font-weight: 600;
}

.auth {
  padding: 13px 20px;
  border-radius: 22px;
}

.cancel {
  background-color: rgb(238, 99, 82);
  border-color: rgb(238, 99, 82);

  &:hover {
    border-color: rgb(238, 99, 82);
  }
}

.authGoogle {
  border-radius: 22px;
  border: 1px solid #dadce0;
}

.more {
  padding: 14px 30px;
  font-weight: 600;
  border-radius: 25px;

  @include media-breakpoint-down(md) {
    padding: 12px 30px;
    width: 100%;
  }
}

.order {
  padding: 12px;
  min-width: 200px;
  font-size: 1.5rem;

  @include media-breakpoint-down(md) {
    margin-top: 14px;
    width: 100%;
  }
}

.send {
  padding: 12px;
  min-width: 200px;
  border-radius: 6px;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .icon {
    transition: transform linear 0.2s;
  }

  &:hover .icon {
    transform: translate(2px, -3px);
  }
}
