.loader {
  // flex-grow: 1;
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--primary-color);
  border-right: 8px solid var(--primary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: transparent;
  animation: spin 0.5s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
